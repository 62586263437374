import React, { useEffect } from "react";
import { useStaticQuery, graphql } from 'gatsby';
import Prism from "prismjs";
import SgLayout from "~layouts/sg-layout";
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import PageSection from '~2-components/PageSection/PageSection';
import PageSectionKontent from '~3-data-components/PageSectionKontent';
import TestimonialItem from '~2-components/TestimonialItem/TestimonialItem';

const pageSectionEg = `import PageSection from '~2-components/PageSection/PageSection';

<PageSection
    backgroundTheme='' //Optional, 'grey' only choice.
    rightColContent={<ReactComponent />} //Required if textLayout='two_columns'. Pass in a React Component. NOTE: Only reused for Testimonials.
>
    // Content goes here
</PageSection>
`;

const SgPageSection = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    const KontentPageSections = useStaticQuery(
        graphql`
            query SGPageSection {
                allKontentItemPageSection {
                    nodes {
                        system {
                            id
                        }
                    }
                }
            }
    `);

    const allPageSections = KontentPageSections.allKontentItemPageSection.nodes;

    return (
        <SgLayout>
            <section className="sg-content">
                <div className="container">
                    <h1>Page Section</h1>
                    <h3>How to use</h3>
                    <CodeBlock
                        code={pageSectionEg}
                    />

                    <h3>Example</h3>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <PageSection>
                        <h2>Page section with white Background</h2>
                        <p>Donec id elit non mi porta gravida at eget metus. Aenean lacinia bibendum nulla sed consectetur. Etiam porta sem malesuada magna mollis euismod. Curabitur blandit tempus porttitor. Aenean eu leo quam. Pellentesque ornare sem lacinia quam.</p>
                    </PageSection>
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h3>Grey background</h3>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <PageSection
                        backgroundTheme="grey"
                    >
                        <h2>Page section with grey Background</h2>
                        <p>Donec id elit non mi porta gravida at eget metus. Aenean lacinia bibendum nulla sed consectetur. Etiam porta sem malesuada magna mollis euismod. Curabitur blandit tempus porttitor. Aenean eu leo quam. Pellentesque ornare sem lacinia quam.</p>
                    </PageSection>
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h3>Grey background Left Aligned</h3>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <PageSection
                        backgroundTheme="grey"
                        leftAlignedContent="left_aligned_text"
                    >
                        <h2>Page section with grey Background</h2>
                        <p>Donec id elit non mi porta gravida at eget metus. Aenean lacinia bibendum nulla sed consectetur. Etiam porta sem malesuada magna mollis euismod. Curabitur blandit tempus porttitor. Aenean eu leo quam. Pellentesque ornare sem lacinia quam.</p>
                    </PageSection>
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h3>Two Column example</h3>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <PageSection
                        backgroundTheme="grey"
                        textLayout="two_columns"
                        rightColContent={<TestimonialItem
                            content="What a pleasure it’s been to work with Shyla, Nicole and Lauren. I just want to thank you for your commitment, honesty and graciousness. You guys have helped me close a door, move forward AND with my dignity in place. Thank you so much."
                            name="Nataly Ceballos"
                        />}
                        >
                        <h2>Two Column Page Section with grey Background</h2>
                        <p>Donec id elit non mi porta gravida at eget metus. Aenean lacinia bibendum nulla sed consectetur. Etiam porta sem malesuada magna mollis euismod. Curabitur blandit tempus porttitor. Aenean eu leo quam. Pellentesque ornare sem lacinia quam.</p>
                    </PageSection>
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h3>All live page sections</h3>
                </div>
            </section>

            {allPageSections.map((item, index) => {
                return (
                    <section key={index} className="project-example">
                        <div className="container">
                            <PageSectionKontent {...item}/>
                        </div>
                    </section>
                );
            })}
        </SgLayout>
    );
};

export default SgPageSection;
